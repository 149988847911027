import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import VueMask from "v-mask";
import VuetifyConfirm from "vuetify-confirm";
import JsonExcel from "vue-json-excel";
import DefaultLayout from "./layouts/Default.vue";

Vue.use(VuetifyConfirm, { vuetify });
Vue.use(VueMask);
Vue.component("downloadExcel", JsonExcel);
Vue.component('Layout', DefaultLayout)

// Developer
//axios.defaults.baseURL = 'http://localhost:3003/api/'
//axios.defaults.baseURL = 'https://intellectum-server.herokuapp.com/api/'

// Production
axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api/`

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
